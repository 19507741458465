import React from "react";
import {graphql} from "gatsby";
import {OurCarsQuery} from "../../../../graphql-types";
import Subpage from "./subpage";

type ReactProps = {
    data: OurCarsQuery
}

const AutocarConfortGrandeCapacite:React.FC<ReactProps> = ({data}) => {

    const selectors = [
        {id: 1, title: data.datoCmsNosVehiculesNew.mainPageButtonTitle, content: data.datoCmsNosVehiculesNew.mainContent, selected: false, link: "/location-autocar"},
        {id: 2, title: data.datoCmsNosVehiculesNew.minibusButtonTitle, content: data.datoCmsNosVehiculesNew.minibusContent, selected: false, link: "/location-autocar/minibus"},
        {id: 3, title: data.datoCmsNosVehiculesNew.autocarEcoButtonTitle, content: data.datoCmsNosVehiculesNew.autocarEcoContent, selected: false, link: "/location-autocar/autocar-eco"},
        {id: 4, title: data.datoCmsNosVehiculesNew.autocarMidicarConfortButtonTitle, content: data.datoCmsNosVehiculesNew.autocarMidicarConfortContent, selected: false, link: "/location-autocar/autocar-midicar-confort"},
        {id: 5, title: data.datoCmsNosVehiculesNew.autocarConfortGrandeCapaciteButtonTitle, content: data.datoCmsNosVehiculesNew.autocarConfortGrandeCapaciteContent, selected: true, link: "/location-autocar/autocar-confort-grande-capacite"},
    ];
    const sContent = selectors[4].content;

    return (
        <Subpage
            data={data}
            selectors={selectors}
            richContent={sContent}
            seo={data.datoCmsNosVehiculesNew.autocarConfortGrandeCapaciteSeo}
            cover={data.datoCmsNosVehiculesNew.autocarConfortGrandeCapacitePageCover[0]}
        />
    )

}

export const pageQuery = graphql`
    query AutocarConfortGrandeCapacite($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }
        datoCmsNosVehiculesNew(locale: { eq: $locale }) {
            ...OurCarsFields
        }
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`

export default AutocarConfortGrandeCapacite;